<template>
  <v-toolbar-title class="mr-4 ml-4">
    <img class="logo-h15" alt="Sony" src="../assets/logo-white.png" />
    &nbsp; | &nbsp;
    <router-link :to="{ name: 'dashboard' }" class="program-title text-decoration-none">
      {{ currentProgram }}
    </router-link>
  </v-toolbar-title>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectedProgram",
  computed: {
    ...mapGetters(["selectedProgram"]),
    currentProgram() {
      let title = "";
      if (this.selectedProgram && this.selectedProgram.name) {
        title = this.selectedProgram.name;
      }
      return title;
    }
  }
};
</script>
