<template>
  <v-menu offset-y v-if="hasAvailableParticipants">
    <template v-slot:activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon v-on="{ ...onMenu, ...onTooltip }" :aria-label="$i18n.translate('Select Program')">
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Select Program") }}</span>
      </v-tooltip>
    </template>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item two-line v-for="(participant, i) in availableParticipants" :key="i" @click="onSelect(participant)">
          <v-list-item-content>
            <v-list-item-title v-text="participant.program.name"></v-list-item-title>
            <v-list-item-subtitle>
              {{ participant.participantType.name }}
              <span v-if="participant.organization"> of {{ participant.organization.name }} </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectedProgramDropdown",
  data: () => ({}),
  computed: {
    ...mapGetters(["availableParticipants"]),
    hasAvailableParticipants() {
      let participants = this.availableParticipants;
      return participants && participants.length > 1;
    }
  },
  methods: {
    onSelect(participant) {
      return this.$auth.storeSelectParticipant(participant).then(() => {
        this.$router.push({ name: "dashboard" }).catch(() => {});
      });
    }
  }
};
</script>
